.avatar {
  height: 100%;
  width: 100%;
}
.cover {
  background-color: rgba(0, 0, 0, 0.35);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.settingsIcon {
  height: 40%;
  width: 40%;
  color: white;
}
.container:hover .cover {
  visibility: visible;
}
.container {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  z-index: 1;
}
.modalContainer {
  display: flex;
  padding: 1rem;
  gap: 4rem;
  align-items: center;
}
.controlsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
}
.controlRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
}

.controlRowTitle {
  width: 5rem;
  text-align: center;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: var(--spacing-xs);
}

.modalAvatar {
  height: 15rem;
  width: 15rem;
}

.controlCheckbox :global(.ant-checkbox-inner),
:global(.ant-checkbox-input) {
  transform: scale(1.3);
}

@media only screen and (max-width: 768px) {
  .controlRow {
    flex: 1 1 8rem;
  }
  .controlsContainer {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .modalContainer {
    flex-direction: column-reverse;
  }
}
