.toolbarEndContainer {
  display: flex;
  margin-inline: 1.5rem;
  align-items: center;
  margin-left: auto;
}
.tabItemContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tabItemDropDown {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 12;
  background-color: white;
  border-top: none;
  border: 1px solid black;
  flex-direction: column;
}

.tabItem:hover .tabItemDropDown > * {
  height: 2rem !important;
}
.tabItem:hover .tabItemDropDown {
  display: flex;
}
#toolbar {
  height: var(--toolbar-height);
  display: flex;
  border-bottom: 1px solid #dedede;
  background-color: white;
  justify-content: start;
}

.nameImageContainer {
  display: flex;
  padding: var(--spacing-md) var(--spacing-xl);
  justify-content: center;
}

.iconButton {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pointer {
  cursor: pointer;
}
.nameImage {
  max-width: 10rem;
  max-height: 1.8rem;
  align-self: center;
  cursor: pointer;
}
.tabItem {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  padding-inline: var(--spacing-md);
  cursor: pointer;
  position: relative;
  color: #708090;
}
.tabItem:hover {
  color: var(--text-color);
}
.tabItem.activeTab {
  border-bottom: 2px solid;
  border-color: var(--supplementary-color);
  color: var(--text-color);
  font-weight: bold;
}
.tabItem span {
  transition: all 0.2s ease-in-out;
}
.tabItem:hover span {
  transform: scale(1.1);
}

.hamburgerMenuIcon {
  display: none;
}

@media only screen and (max-width: 768px) {
  .hamburgerMenuIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 1rem;
    cursor: pointer;
  }
  .hamburgerMenuIcon.active {
    color: var(--supplementary-color);
  }
  .hamburgerMenuIcon.inactive {
    color: var(--secondary-color);
  }
  .nameImage {
    margin: auto;
  }

  #toolbar {
    position: fixed;
    width: 100%;
    z-index: 4;
  }
  .tabItemContainer {
    padding-top: 1rem;
    border-top: 3px solid var(--supplementary-color);
    margin-top: var(--toolbar-height);
    position: fixed;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    height: 100%;
    width: 20rem;
    left: -200vw;
    transition: all 0.3s ease-in;
  }
  .backDrop.display {
    margin-top: var(--toolbar-height);
    position: fixed;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(5px);
  }
  .tabItemContainer.display {
    left: 0;
    transition: all 0.3s ease-out;
  }
  .tabItem {
    height: 2rem;
    font-weight: 600;
    font-size: 20px;
    width: 80%;
    border-radius: 20px;
    border: none;
    margin: 0rem 0.5rem;
  }
  .tabItem.activeTab {
    background-color: var(--primary-color-light);
    border: none;
  }
}
@media only screen and (max-width: 1024px) {
  .nameImage {
    max-height: 1.5rem;
  }

  .tabItem {
    /* width: unset; */
    padding-inline: var(--spacing-xs);
  }
}
