.equipmentModalContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.container {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.equipmentListItem {
  border: 1px solid #d9d9d9;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 11px;
  cursor: pointer;
  flex: 1;
  overflow: hidden;
}
.equipmentListItemHidden {
  background-color: #e9e9e9;
}
.equipmentListItem:hover {
  border-color: var(--secondary-color);
}
.actionIcon {
}
.equipmentTypeSelector {
  width: 30%;
  margin-left: 10px;
}

.equipmentListItemText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
  text-align: center;
  flex: 1;
}
