.body {
  display: flex;
  width: 40vw;
  height: 60vh;
  flex-direction: column;
}
.filterContainer{
  display: flex;
  gap: .5rem;
  margin-bottom: 1rem;
  padding-inline: .5rem;
  flex-wrap: wrap;
}
.mapFilter{
  flex: 2 1 10rem;
  overflow: hidden;
}
.dateFilter{
  flex: 1 1 10rem;
}
.userFilter{
  flex: 2 1 10rem;
  overflow: hidden;
}
.datePickers{
  gap: inherit;
  display: flex;
  flex: 1 15rem;
}

@media only screen and (max-width: 1024px) {
  .body {
    width: 80%;
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .body {
    width: 90%;
  }
}
