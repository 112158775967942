.checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 0.1em solid #d9d9d9;
  border-radius: 0.2em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: var(--secondary-color);
}

.checkbox:checked::before {
  transform: scale(1);
  background-color: white;
}
.checkbox:checked {
  background-color: var(--secondary-color);
}

.checkbox:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.checkbox:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
