
.userListItemContent {
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  flex: 1;
}

.userListTeamName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.userListItemTitle {
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.userListItemContainer {
  display: flex;
  height: 4rem;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.094);
}
