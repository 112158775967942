.container {
  position: fixed;
  z-index: 10;
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  justify-content: center;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
}
.header > svg{
    cursor: pointer;
}
.control {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.control > span {
  flex: 1;
  font-size: .9rem;
  font-weight: 500;
}
.increaseDecreaseContainer{
    display: flex;
    align-items: stretch;
}
.increaseDecreaseContainer > span{
    padding-inline: .5rem;
    margin-inline: .5rem;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    display: flex;
    align-items: center;
}