.teamColorIndicator {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
  margin-left: auto;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
.controlContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
}
.listItemMetadata {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.deskListItemContent {
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;
  justify-content: center;
  gap: 10px;
  flex: 1;
  overflow: hidden;
}
.deskListItemTitle {
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.deskListItemContainer {
  display: flex;
  height: 4rem;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.094);
}
.deskListItemMetadata {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.buttonContainer {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.bookButton {
  height: 5rem;
  font-weight: 500;
  display: flex;
  flex: 1;
  border-radius: 10px;
  color: var(--primary-text-color);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  --gradient-rotation: 135deg;
  background: linear-gradient(
    var(--gradient-rotation),
    var(--primary-color) 20%,
    var(--secondary-color) 60%
  );
  transition: all 0.3 ease-in;
}
.bookButton:hover {
  --gradient-rotation: 45deg;
}
.bookButton.disabled{
  background: var(--metadata-color);
  cursor: not-allowed;
}
.listContainer {
  overflow: auto;
  padding-inline: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.deskListContainer {
  display: flex;
  flex-direction: column;
}
.datePicker{
  margin-bottom: var(--spacing-sm);
}
@media only screen and (min-width: 768px) {
  .bookButton {
    height: 4rem;
  }
}
