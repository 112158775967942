.selectionColor {
  margin-left: auto;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.teamColorIndicator {
  margin-left: 10px;
  height: 20px;
  aspect-ratio: 1;
  border-radius: 10px;
}
.selectionText {
  display: inline-block;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selectionContainer {
  display: flex;
  align-items: center;
}
.selector {
  flex: 1;
  overflow: hidden;
}
.dropdownTeamName{
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  flex: 1;
}