.canvasContainer {
  height: var(--appHeight);
}
#mapContainer {
  position: relative;
  grid-column-start: 2;
  grid-row: 1/-1;
}
.datePickerContainer {
  grid-row: 1;
}
.outletContainer {
  grid-column: 1;
  grid-row: 2 / last-line;
  display: flex;
  flex-direction: column;
  position: relative;
}
.gridContainer {
  display: grid;
  grid-template-columns: var(--tableContainerWidth) 1fr;
  grid-template-rows: 1fr 10fr;
  width: 100%;
  background-color: transparent;
}
#content {
  display: flex;
  flex-direction: row;
  position: relative;
}
.mapSelectorContainer {
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 15rem;
  padding-inline: 2rem;
  border-bottom: 1px solid var(--border-color);
  background-color: #f3f3f3;
  display: flex;
  height: 2.5rem;
  align-items: center;
  gap: 2px;
  border-radius: 0 0 20px 20px;
}
.mapSelectorSelect {
  overflow: hidden;
  flex: 1;
}
.mobileFloatingIcon {
  display: none;
}

@media only screen and (min-width: 768px) {
  .outletContainer {
    margin-right: 0;
    margin-top: 0;
  }
}
@media only screen and (max-width: 768px) {
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;
    width: 100%;
  }
  #mapContainer {
    z-index: 1;
    grid-column-start: 1;
    grid-row: 2/-1;
    margin-top: 0;
  }
  .outletContainer{
    margin-top: 0;
    z-index: 2;
    grid-column-start: 1;
    grid-row: 2/-1;
  }
  #content {
    z-index: 3;
  }
  .mobileFloatingIcon.highlightedMobileFloatingIcon {
    animation-play-state: running;
    animation-iteration-count: 1;
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-duration: 1s;
  }
  .hideOnMobile {
    display: none !important;
  }
  .mobileFloatingIcon {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    color: white;
    border-radius: 50%;
    padding: 1rem;
    z-index: 9;
    background-color: var(--secondary-color);
    display: flex;
  }
  .mapSelectorContainer {
    width: calc(100% - (2 * var(--space4)));
    border-radius: 0;
    padding-inline: 1rem;
    clip-path: none;
    height: 3rem;
  }
}
@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-40px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
