.content {
  display: flex;
  align-self: start;
  padding-inline: 2rem;
  flex-direction: column;
  width: calc(100% - 4rem);
}
.themeColor {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.colorContainer {
  padding: 0.5rem;
  gap: var(--spacing-sm);
  display: flex;
  width: 13rem;
  flex-direction: column;
}
.defaultButton {
  width: 50%;
  align-self: flex-start;
}
.saveButton {
  margin-top: var(--spacing-lg);
  align-self: center;
  padding-inline: 3rem;
}
#fileUploadInput {
  display: none;
}
#imagePreview {
  height: auto;
  max-width: 100%;
  max-height: 5rem;
}
.title {
  font-weight: 500;
  padding-block: var(--spacing-sm-plus) var(--spacing-sm);
}
.detailsContainer {
  padding-block: var(--spacing-xs);
  max-width: 20rem;
}
.imagePreviewContainer {
  margin: var(--spacing-sm);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs) var(--spacing-sm);
  flex-wrap: wrap;
}
.imageUploadContainer {
  display: flex;
  gap: 1rem;
  padding-top: 0.5rem;
}
