
.legendPopup {
    box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  
    display: flex;
    position: absolute;
    background: white;
    padding: 10px;
    gap: 0.5rem;
    align-items: center;
    border-radius: 10px;
  }
  