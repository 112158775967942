.workDaySwitch {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
}
.workDaySwitch:hover {
  border: 2px solid var(--secondary-color);
  margin: -1px;
}
.workDaySwitchColor {
  height: 1rem;
  background-color: red;
  flex: 1;
}
.workDaySwitchDay {
  background-color: var(--primary-color);
  color: white;
  font-weight: 500;
  font-size: 20px;
  flex: 1;
  text-align: center;
  padding: 3px;
}
.mapWorkDayContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-block: 1rem;
  justify-content: center;
}
.mapStatusContainer {
  margin-block: 1rem;
}
