.button {
  border: none;
  border-radius: 6px;
  height: 32px;
  font-size: var(--font-size-sm);
  padding: 0 var(--spacing-md);
  box-shadow: 0 2px 0 rgba(9, 55, 117, 0.13);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}
.optionContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3rem;
  display: flex;
  align-items: center;
}
.optionContainer > div {
  border-left: 1px solid var(--secondary-text-color);
  display: flex;
  height: 60%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
}
.optionContainer:hover > div > svg {
  transform: rotate(0.5turn);
}
.optionContainer > div > svg.open {
  transform: rotate(0.5turn);
  color: var(--supplementary-color);
}
.optionContainer > div > svg {
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.optionContainer:hover {
  background-color: var(--secondary-color);
  color: var(--supplementary-color);
  filter: brightness(120%);
}
.button:active:not(:has(.optionContainer:active)) {
  box-shadow: 0 0 0 2px var(--supplementary-color);
}
.button:global(.primary) {
  color: var(--secondary-text-color);
  background-color: var(--secondary-color);
}
.button:global(.primary):disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.button:global(.primary):hover:not(:has(.optionContainer:hover)):disabled {
  background: rgba(0, 0, 0, 0.04);
}

.button:global(.primary.danger) {
  color: white;
  background-color: rgb(255, 77, 79);
}
.button:global(.primary.danger):hover:not(:has(.optionContainer:hover)) {
  background-color: rgb(255, 186, 187);
}

.button:global(.primary):hover:not(:has(.optionContainer:hover)) {
  background-color: var(--secondary-color-light);
}

.button:global(.default) {
  background-color: white;
  box-shadow: 0 0 0 1px #d3d3d3;
}
.button:global(.default):hover:not(:has(.optionContainer:hover)) {
  background-color: #f3f3f3;
}

.button:global(.link) {
  background-color: white;
  box-shadow: none;
  color: #1677ff;
}
.button:global(.link):hover:not(:has(.optionContainer:hover)) {
  background-color: transparent;
  color: #69b1ff;
}

.button:focus-visible {
  outline: 4px solid #a7b6d4;
  outline-offset: 1px;
  transition: outline-offset 0s, outline 0s;
}
.button:global(.large) {
  font-size: 16px;
  line-height: 1.5;
  height: 40px;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: 8px;
}
.dropdown {
  display: none;
}
.option {
  cursor: pointer;
  padding: var(--spacing-sm) var(--spacing-md);
  overflow: hidden;
  font-weight: 500;
  font-size: var(--font-size-sm);
}
.option:hover {
  background-color: #f3f3f3;
}

.options{
  position: fixed;
  border: 1px solid #d3d3d3;
  transform: translate(-50%,-110%);
  border-radius: 6px;
  background-color: white;
  width: 12rem;
  z-index: 100;
}
.options.open{
  display: block;
}