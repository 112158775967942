.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 50vw;
  position: relative;
  transition: all 0.3s ease-in;
}
.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: transparent;
}
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  align-items: center;
  overflow: auto;
  height: 100%;
}
.goBack {
  display: none;
}
.header {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-size: 22px;
  font-weight: 500;
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  padding-block: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .outerContainer {
    position: relative;
    height: var(--appHeight);
    justify-content: unset;
    align-items: unset;
  }
  .goBack {
    position: absolute;
    right: 2rem;
    display: flex;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1024px) {
  .container {
    justify-content: flex-start;
    box-shadow: none;
    height: 100%;
  }
}
