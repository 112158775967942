.container {
  position: absolute;
  top: 100%;
  right: 0%;
  background-color: #fdfdfd;
  width: 25rem;
  border: 2px solid var(--border-color);
  border-radius: 0 0 15px 15px;
  z-index: 12;
  padding: 1rem;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
}
.notificationListContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.listItemTitle {
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.hidden {
  display: none;
}
.listItemContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.title {
  font-size: 18px;
  font-weight: 500;
  display: table;
  padding-bottom: 1rem;
}
.listItemContainer {
  margin-block: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
}
.notificationAge {
  font-weight: 500;
  font-size: 16px;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .container {
    right: 0;
    width: calc(100vw - 2rem);
    max-height: 100dvh;
    position: fixed;
    top: 3.5rem;
  }
}
