.datePickerContainer {
  display: flex;
  padding-inline: var(--spacing-sm-plus);
  padding-block: var(--spacing-sm);
}
.datePickerContainer button:first-child {
  border-right: none;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.datePickerContainer button:last-child {
  border-left: none;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.datePicker {
  flex: 1;
  border-left: none;
  border-right: none;
  border-radius: 0;
  justify-content: center;
}

.bookNowButton {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 10rem;
  z-index: 3;
}
