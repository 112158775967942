.label {
  font-weight: 500;
}
.labelText {
  margin-bottom: var(--spacing-xs);
}
.required::before {
  content: "* ";
  color: red;
}
