.bubble{
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
    border-radius: 50%;
    text-align: end;
    vertical-align: bottom;
    margin-inline: .3rem;
}
.icon{
    transform: translateY(3px);
}