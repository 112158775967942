.header {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.logo {
  height: 2rem;
}

.welcomeText {
  font-size: 1.5rem;
  font-weight: 500;
}
.body {
  flex: 1;
  padding-block: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginMethodContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem
}
.passwordButton{
    border: 1px solid rgb(218, 220, 224);
    background-color: white;
    color:#3c4043;
    width: 100%;
    height: 40px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 3px;
    font-size: 14px;
}
.passwordButton:hover{
    background-color: rgb(234, 236, 240);
}