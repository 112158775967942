.container {
    position: absolute;
    bottom: 0;
    right: 0;
    --borderColor: #cccccc;
    background-color: #fdfdfd;
    border: 2px solid var(--borderColor);
    padding: 0.5rem;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 10px;
    display: flex;
    gap: 0.3rem;
    z-index: 1;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      display: none;
    }
  }
  