.teamColorButton {
  display: flex;
  height: 2rem;
  gap: 3px;
}
.colorPickButtonContainer:hover {
  border: 1px solid var(--secondary-color);
}
.colorPickButtonContainer:active {
  border: 2px solid var(--primary-color);
  margin: -1px;
}
.colorPickButtonContainer {
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
}
.chooseColorText {
  flex-grow: 1;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  border: .5px solid #dedede;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
