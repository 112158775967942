#userCreateViewForm{
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    height: 100%;
}
.body{
    display: flex;
    align-items: stretch;
    margin-inline: 25%;
    flex-direction: column;
}
#buttonContainer{
    margin-top: 1rem;
}
#buttonContainer button{
    width: 6rem;
}
#userCreateContainer{
    border: 1px solid #f0f0f0;
    width: 60%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 20px;
}

@media only screen and (max-width: 768px) {
    #userCreateViewForm{
        width: 80%;
    }
    .body{
        margin: 0;
    }
  }