.formInputs {
  padding: 1rem;
  padding-bottom: 0;
}
.bookableText {
  margin: 1rem;
  font-size: 20px;
  text-align: center;
}
.userSelection {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: var(--spacing-sm);
  overflow: hidden;
}
.flexContainer {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }