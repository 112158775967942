.container {
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
}
.content {
  height: 100dvh;
  z-index: 2;
  position: relative;
}
