.loadingContainer {
  background-color: #ffffffb1;
}
.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.indicator {
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.errorContainer {
  background-color: #ffffffb1;
}
