.reportTypesPicker {
  display: flex;
  margin-bottom: 0;
  margin-right: 0;
}
.reportTypeItem {
  aspect-ratio: 1;
  flex: 1;
  background-color: #cecece;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: white;
  flex-direction: column;
  height: 3rem;
  width: 4rem;
}
.reportTypeItem > span {
  font-size: 10px;
  font-weight: 500;
}
.reportTypeItem.active {
  background-color: var(--primary-color);
  color: var(--supplementary-color);
}
.reportTypeItem.active:hover {
  background-color: var(--primary-color);
  opacity: 0.8;
}
.reportTypeItem:hover {
  background-color: #cecece;
  opacity: 0.8;
}
.viewContainer {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  overflow: auto;
  align-items: flex-start;
  align-content: flex-start;
}
