.descriptionContainer {
  border: 1px solid #dedede;
  border-radius: 5px;
  background-color: #fff;
  padding: 0.5rem 0.5rem;
}
.actionContainer {
  display: flex;
  justify-content: flex-end;
}
.metadataContainer {
  margin-top: 0.5rem;
  gap: 0.3rem;
  overflow: hidden;
}
.metadata {
  font-size: 14px;
  display: flex;
  padding-block: 0.2rem;
  gap: 0.5rem;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.metadata strong {
  padding-right: 0.5rem;
}

.modalTitle {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
}
