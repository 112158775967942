.formContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.timeSlotContainer {
  height: 2rem;
  border: 1px solid #dedede;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  padding-inline: 0.5rem;
}
.formBody {
  display: flex;
  flex-direction: column;
}
.bookingTitle {
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  padding-block: 1rem;
}
.buttonContainer {
  padding: 1rem;
}
.equipmentHeader {
  margin-top: 1rem;
}
.userSelector {
  margin: 0.5rem 1rem;
}
.bookingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.bookingOptionContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: start;
  padding-block: var(--spacing-xs);
  align-self: flex-start;
}
