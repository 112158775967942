.flexContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.formContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tagSection{
  padding-inline: var(--spacing-md);
  padding-bottom: var(--spacing-lg);
}
.equipmentTitle {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: var(--spacing-md);
  flex: 1;
}
.buttonContainer {
  display: flex;
  padding: var(--spacing-md);
  padding-bottom: 1.5rem;
  flex-direction: column;
  gap: var(--spacing-sm-plus);
}
.container {
  display: flex;
  flex-direction: column;
  height: var(--appHeight);
}
.equipmentListContainer {
  gap: 0.25rem;
}
.deleteDialogContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
