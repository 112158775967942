.buttonContainer{
    width: 20rem;
    display: flex;
    justify-content: space-around;
    margin-top: .5rem;
}
#nameInput{
    width: 20rem;
}
.content{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem
}