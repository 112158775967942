.buttonContainer {
  margin-top: 1rem;
  justify-content: space-between;
  display: flex;
  padding-inline: var(--spacing-xl);
}
.buttonContainer button {
  min-width: 6rem;
}
.equipmentForm {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.formBody {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.formRow {
  width: 100%;
  display: flex;
  align-content: stretch;
  gap: 1rem;
}
.formRow > * {
  flex: 1;
}

.associationContainer{
  width: 100%;
  padding-top: var(--spacing-md);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .formRow {
    flex-direction: column;
  }
  .equipmentForm{
    width: 80%;
  }
  .buttonContainer{
    padding: 0;
    gap: 2px;
  }
}
