
.animatingListItem.active {
    transform: scale(1.04);
  }
  
  .animatingListItem:not(.active):hover {
    transform: scale(1.02);
  }
  
  .animatingListItem {
    transform: scale(1);
  }