#mapEditContainer {
  position: relative;
  width: calc(100vw - 10rem);
}
#mapEditCanvas {
  width: calc(100vw - 10rem);
}
.mapActionsContainer {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  right: 2rem;
  top: 1rem;
  z-index: 1;
}
#content {
  display: flex;
  flex-direction: row;
}
#mapInfoIcon {
  color: #0f0f0f;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;
}
.mapCorner {
  --borderColor: #cccccc;
  position: absolute;
  background-color: #fdfdfd;
  border: 2px solid var(--borderColor);
  padding: 0.5rem 1rem;
  z-index: 2;
}
.mapCornerTopRight {
  right: 0;
  top: 0;
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 10px;
}
.mapCornerTopLeft {
  left: 0;
  top: 0;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 10px;
}
.mapCornerBottomRight {
  right: 0;
  bottom: 0;
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 10px;
}
.mapCornerBottomLeft {
  bottom: 0;
  left: 0;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 10px;
}
#mapNameEditContainer {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 13rem;
  border-bottom: 1px solid #dedede;
  padding-inline: 0.5rem;
  display: flex;
  height: 2.5rem;
  align-items: center;
  background-color: #f3f3f3;
  justify-content: center;
  border-radius: 0 0 20px 20px;
}
.canvasContainer {
  position: unset;
  width: 90vw;
}
.mapNameInput {
  width: auto;
}
