.controlsContainer {
  padding: 0.8rem 1rem;
  display: flex;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
.listItemName {
  flex: 1;
}
.listItemContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-block: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.094);
}
