.listCloser {
  padding-block: var(--spacing-sm-plus) var(--spacing-xs);
  display: flex;
  border-bottom: 2px solid black;
  cursor: pointer;
}
.listCloser:hover {
  border-bottom: 2px solid var(--secondary-color);
}
.listCloserText {
  display: flex;
  flex: 1;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-inline: 0.5rem;
}
.listCloserText > * svg {
  color: var(--secondary-color);
}
.listCloserText:hover > * svg {
  color: var(--supplementary-color);
}
