#teamListView {
  width: var(--tableContainerWidth);
  height: var(--appHeight);
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  flex-direction: column;
}
#teamViewContainer {
  width: calc(100vw - var(--tableContainerWidth));
}
.teamListItemName {
  font-size: 14px;
  margin-left: 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}
.teamListItemContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-block: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.094);
}
.teamListContainer {
  display: flex;
  flex-direction: column;
}
.teamListItemColorContainer {
  margin-block: 0.5rem;
  border-radius: 5px;
}
.teamListItemColorContainer.square {
  height: 2rem;
  width: 2rem;
}
.teamListItemColorContainer.rect {
  height: 2rem;
  width: 3rem;
}
