.form{
    display: flex;
    padding-block: 1rem;
    flex-direction: column;
    height: 100%;
}
.buttonContainer{
    padding-inline: 1rem;
    display: flex;
    flex-direction: column;
}
.inputs{
    padding-inline: 1rem;
}