.plusTag {
  display: flex;
  align-items: center;
  border-style: dashed;
  cursor: pointer;
}
.plusTag:hover {
  border-color: transparent;
  box-shadow: 0 0 0 1px var(--primary-color);
}
.tagInput {
  width: 5rem;
  line-height: 12px;
}

.container {
  padding-top: var(--spacing-xs);
}
