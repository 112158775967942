#listContainer {
  width: var(--tableContainerWidth);
  border-right: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  flex-direction: column;
}
#viewContainer {
  width: calc(100vw - var(--tableContainerWidth));
  background-color: transparent;
}
@media only screen and (max-width: 768px) {
  #listContainer {
    width: 100vw;
  }
  #viewContainer {
    position: absolute;
    width: 100vw;
  }
}
@media only screen and (min-width: 768px) {
  #listContainer:global(.borderBox){
    margin-right: 0;
  }
}
