#mapEditControls {
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dedede;
}
.mapEditControlsTitle {
  font-size: 2rem;
  margin: 0.5rem;
  font-weight: 500;
}

.controlBox {
  border-bottom: 1px solid #dedede;
  cursor: pointer;
  height: 15%;
}
.controlBoxImage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#controlsColumn {
  display: block;
  width: 100%;
  overflow: auto;
}
