body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: var(--background-color);
  letter-spacing: 0.2px;
  color: var(--text-color);
}

.borderBox {
  margin: var(--spacing-sm-plus);
  border: 2px solid var(--border-color);
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

* {
  font-family: "Roboto", sans-serif;
}
.hidden {
  display: none !important;
}
:root {
  --toolbar-height: 3.5rem;
  --appHeight: calc(100dvh - var(--toolbar-height));
  --tableContainerWidth: 25rem;

  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-sm-plus: 12px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;

  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-xxl: 24px;
  --font-size-xxxl: 32px;
  --border-color: #e5e4e2;
  --background-color: #f5f5f5;
  --text-color: #101720;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.firebase-emulator-warning {
  display: none;
}
.container {
  border: 1px solid black;
  margin: 10px;
  height: 50%;
}
canvas {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.fullHeight {
  height: 100%;
}

.overflowAuto {
  overflow: auto;
}
.appContent {
  height: var(--appHeight) !important;
  width: 100vw !important;
  overflow-x: hidden;
}
.appContent > * {
  background-color: white;
}
.paddingTop {
  padding-top: 1rem;
}
.centeredFlexBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabledSelect .ant-select-selector {
  background-color: transparent !important;
  color: #000 !important;
  cursor: auto !important;
}
.disabledSelect .ant-select-selector .ant-select-selection-item {
  user-select: auto;
}
.disabledSelect .ant-select-arrow {
  display: none;
}

.listItemMetadata {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.devIndicator {
  background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  height: 1rem;
}
.paneSidesRight .ant-picker-panel:nth-child(1) {
  display: none;
}
.paneSidesRight .ant-picker-panel:nth-child(2) button {
  visibility: visible !important;
}
@media only screen and (max-width: 625px) {
  .paneSidesBoth .ant-picker-panel:nth-child(1) {
    display: none;
  }
  .paneSidesBoth .ant-picker-panel:nth-child(2) button {
    visibility: visible !important;
  }
  .paneSidesRight .ant-picker-panel:nth-child(2) {
    display: block;
  }
  .paneSidesRight .ant-picker-panel:nth-child(1) button {
    visibility: visible !important;
  }
  .ant-picker-panel:nth-child(2) {
    display: none;
  }
  .ant-picker-panel:nth-child(1) button {
    visibility: visible !important;
  }
}
@media only screen and (max-width: 1024px) {
  .borderBox {
    margin: var(--spacing-sm);
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: auto;
    overflow-y: auto;
  }
  .appContent {
    top: var(--toolbar-height);
    position: relative;
  }

  .borderBoxOnTablet {
    margin: 0;
    border-radius: 0;
    overflow: hidden;
  }
}
