.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: var(--spacing-lg);
}

.image {
  height: 14rem;
  width: 14rem;
  padding-block: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.iconContainer {
  display: flex;
  justify-content: center;
  font-size: var(--font-size-xl);
}
.checkIcon {
  color: #52cfa9;
}
.infoIcon {
  color: #517FFF;
}
.innerIconContainer > svg {
  --dim: 45px;
  width: var(--dim);
  height: var(--dim);
}
.innerIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-bottom: var(--spacing-lg);
  margin-top: var(--spacing-sm);
}
.innerIconContainer:has(.checkIcon) {
  background-color: #d2f7eb;
  box-shadow: 0 0 0 6px #d2f7eb;
}
.innerIconContainer:has(.infoIcon) {
  background-color: #e0e8ff;
  box-shadow: 0 0 0 6px #F3F6FF;
}
.dangerIcon{
  color: rgb(255, 77, 79);
}
.innerIconContainer:has(.dangerIcon) {
  background-color: rgb(255, 186, 187);
  box-shadow: 0 0 0 6px rgb(255, 232, 232);
}
.buttonContainer button {
  min-width: 5rem;
  font-weight: 500;
}
.headingContainer {
  flex: 1;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-lg);
}
.subHeadingContainer {
  padding-top: var(--spacing-xs);
  font-size: var(--font-size-md);
  text-align: center;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.buttonContainer > button * svg{
  --dim: 20px;
  width: var(--dim);
  height: var(--dim);
  padding-right: var(--spacing-sm);
}
.denyButton {
  color: #444;
}
@media only screen and (max-width: 768px) {
  .buttonContainer {
    justify-content: stretch;
  }
  .buttonContainer button {
    flex: 1;
    font-size: var(--font-size-md);
  }
  .buttonContainer > button * svg{
    padding-inline: var(--spacing-xs);
  }
}
