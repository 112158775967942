.bookingItemDateContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}
.collapsedView.collapsed {
  display: none;
}
.bookingItemContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.collapsedView {
  margin-top: -1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  z-index: 1;
  border-radius: 5px;
  background-color: #fff;
  padding-inline: 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: flex;
  align-items: center;
}
.collapsedActionContainer {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.collapseIndicator {
  margin-left: auto;
  margin-right: 0.5rem;
}
.collapseIndicator.collapsed {
  transform: rotate(0deg);
  transition: transform 150ms ease-out;
}
.collapseIndicator.notCollapsed {
  transform: rotate(180deg);
  transition: transform 150ms ease-in;
}
.collapsedView.notCollapsed {
  display: flex;
}
.bookingItem {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
  height: 4rem;
  display: flex;
  background-color: #fff;
  padding: 0.2rem 0.5rem;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}
.metadata {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: rgba(0, 0, 0, 0.45);
}
.bookingItem:hover {
  box-shadow: var(--primary-color-light) 0px 0px 0px 3px;
}
.userName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bookingItemName {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bookingItemButton {
  margin-left: auto;
}
.deleteButton {
  margin-right: var(--spacing-md);
}

@media only screen and (max-width: 600px) {
  .deleteButton {
    margin-right: var(--spacing-xs);
  }
}
