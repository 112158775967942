.container {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}
.title {
  box-shadow: 0 0 0 1px #d9d9d9;
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 11px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5rem;
}
.title:hover {
  box-shadow: 0 0 0 2px #d9d9d9;
}
.participantContainer {
  max-height: 150px;
  overflow: auto;
  padding: var(--spacing-xs) var(--spacing-sm);
}
