.buttonContainer {
  margin-top: 1rem;
  justify-content: space-between;
  display: flex;
  padding-inline: 20%;
}
.buttonContainer button {
  width: 6rem;
}
.formBody {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.formRow{
    width: 100%;
    display: flex;
    align-content: stretch;
    gap: 1rem;
}
.formRow > *{
    flex: 1;
}
