
.container {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.mapDropDown {
  width: 20rem;
}
.chartModifiers{
  display: flex;
  gap: inherit;
}
.viewContainer {
  display: flex;
  width: 100vw;
  height: var(--appHeight);
  flex-direction: row;
}
.chartContainer {
  position: relative;
  margin: auto;
  height: 70vh;
}
.legendPopup {
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  display: flex;
  position: absolute;
  background: white;
  padding: 10px;
  gap: 0.5rem;
  align-items: center;
  border-radius: 10px;
}
