.title {
  font-weight: bold;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  text-align: center;
  margin-left: -1rem;
}
.options {
  color: var(--primary-text-color);
  cursor: pointer;
}
.container {
  height: 50px;
  position: relative;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem;
}
.navigationButton {
  color: var(--primary-text-color);
  padding-right: 1rem;
}
.growOnHover{
  transition: all 0.2s ease-in-out;
}
.growOnHover:hover {
  transform: scale(1.3);
  color: var(--supplementary-color);
}