.searchContainer {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1rem;
}
.timePicker {
  width: 100%;
}
.listContainer {
  overflow: auto;
  padding-inline: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}
.searchExplanationText {
  font-size: 1rem;
}
.timeRangePresetsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding-inline: 0.2rem;
}

.timeRangePresetsContainer > span:first-child {
  font-weight: 500;
  text-align: center;
  font-size: 20px;
}
