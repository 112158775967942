#teamViewForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.1rem;
  min-width: 20rem;
}
#buttonContainer {
  margin-top: var(--spacing-sm-plus);
}
#buttonContainer button {
  width: 6rem;
}

@media only screen and (max-width: 768px) {
  #teamViewForm{
    width: 80%;
  }
}
