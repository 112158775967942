.container {
  height: 3rem;
  margin-bottom: 0;
}

.innerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dateContainer {
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: var(--font-size-xl);
}
.calendarIcon {
  padding-inline: var(--spacing-md);
  color: var(--primary-color);
}
.datePicker {
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #fafafa;
  height: var(--spacing-xl);
  margin-inline: var(--spacing-sm);
}
.datePicker * input {
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: var(--font-size-lg) !important;
  cursor: pointer;
  color: var(--text-color) !important;
}
.arrowButton {
  all: unset;
  display: flex;
  height: 100%;
  align-items: center;
  padding-inline: var(--spacing-md);
  cursor: pointer;
  transition: all 0.1s;
  transform: scale(1);
  user-select: none;
}
.arrowButton:hover:not(:disabled) {
  background-color: #f3f3f3;
  transform: scale(1.1);
}
.arrowButton:disabled{
  cursor:not-allowed;
  background-color: #f3f3f3;
}
.dateContainer {
  align-items: center;
  height: 100%;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .container {
    margin-right: 0;
  }
  
}