.background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -100;
}
.appIcon {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 15%;
  height: 3rem;
}
.backGroundShape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.backGroundShape svg {
  position: relative;
  display: block;
  width: calc(150% + 1.3px);
  height: 55vh;
}

.backGroundShape .shapeFill {
  fill: var(--secondary-color);
}
