
#loginContainer {
  background-color: #fefefe;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 20rem;
  z-index: 2;
}
