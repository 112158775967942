.container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px 0px;
}

.tag {
  color: var(--primary-text-color);
  font-weight: 500;
  border-color: var(--primary-color) !important;
  user-select: none;
  max-height: 1.5rem;
}
.tag:hover {
  border-color: transparent;
  box-shadow: 0 0 0 1px var(--primary-color);
}
.tag :global(.ant-tag-close-icon:hover) svg {
  fill: red;
}
