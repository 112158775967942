.searchContainer {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1rem;
}

.listContainer {
  overflow: auto;
  padding-inline: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}
.searchExplanationText {
  font-size: 1rem;
}
