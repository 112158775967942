.formColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.bodyContainer {
  display: flex;
  gap: 2rem;
  height: 100%;
}
#title {
  margin-bottom: 2rem;
}
#buttonContainer {
  margin-top: var(--spacing-lg);
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-evenly;
}
#buttonContainer button {
  width: 6rem;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--appHeight);
}
.userEditContainer {
  border: 1px solid #f0f0f0;
  width: 80%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  border-radius: 20px;
  padding: 1rem;
  height: 40vh;
}
.cardAvatar {
  position: absolute;
  top: 12px;
  left: 20px;
  height: 105px;
  width: 105px;
  border: 5px solid white;
}
.header {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 30rem;
  padding-inline: 10rem;
}

@media only screen and (max-width: 768px) {
  .bodyContainer {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .formColumn {
    width: 100%;
    flex: none;
  }
  .header {
    text-overflow: clip;
    max-width: 20rem;
  }
  .cardAvatar {
    width: 90px;
    height: 90px;
  }
}
