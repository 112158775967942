.container {
  position: absolute;
  bottom: 0;
  left: 0;
  --borderColor: #cccccc;
  background-color: #fdfdfd;
  border: 2px solid var(--borderColor);
  padding: 0.5rem;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 10px;
  display: flex;
  gap: 0.3rem;
  z-index: 1;
}
.countEntryCount {
  font-weight: 500;
  font-size: 18px;
}
.countEntryMeta {
  font-size: 13px;
  font-weight: 500;
  color: var(--metadata-color);
}
.countEntryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    display: none;
  }
}
