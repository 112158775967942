.equipmentListHeader {
  display: flex;
  flex-direction: row;
  margin-inline: 1rem;
  position: relative;
  justify-content: center;
  font-weight: 500;
  font-weight: bold;
  text-align: center;
  padding-bottom: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
  margin-top: 0.5rem;
}

.equipmentAddButton {
  position: absolute;
  right: 0;
  top: -5px;
  overflow: visible;
  width: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
}
.equipmentContainer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}
.equipmentModalButtonContainer {
  display: flex;
  justify-content: center;
  gap: 4rem;
}
.equipmentDropdown {
  width: 20rem;
}
.equipmentModalButtonContainer button {
  min-width: 4rem;
}
.equipmentModalContainer {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
@media only screen and (max-width: 768px) {
  .equipmentContainer {
    gap: 0.2rem;
    /* height: 10rem; */
  }
}
