.tagContainer {
  display: flex;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
  gap: 0.4rem 0rem;
  overflow: hidden;
}
.formEntryContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #9f9f9f;
  padding: 0.5rem;
}
.formEntryTitle {
  padding-left: 0.5rem;
  padding-bottom: 0.1rem;
  padding-top: 0.4rem;
  font-weight: 500;
  font-size: 15px;
}
.fieldsContainer {
  padding: 0.8rem 1rem;
}
.formEntryTitle.required::before {
  content: "* ";
  color: red;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}
.buttonContainer {
  display: flex;
  padding: 1rem 1rem;
}
.buttonContainer > * {
  flex: 1;
}
.bookingSuccessContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 2rem;
  max-width: 40rem;
}
.bookingSuccessContainer > span{
  text-align: start;
  font-weight: 500;
}
.copyContainerText {
  overflow: hidden;
  text-wrap: nowrap;
  font-weight: 400;
  color: #4f4f4f;
  user-select: none;
  text-overflow: ellipsis;
}
.copyContainer {
  border: 2px solid #cfcfcf;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: space-between;
}

.container {
  height: 100%;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .formEntryTitle {
    font-size: 17px;
  }
}
