.equipmentListItemContent {
  display: flex;
  flex-direction: column;
  margin-left: 0.7rem;
  justify-content: center;
  gap: var(--spacing-xs);
  overflow: hidden;
  flex: 1;
}
.equipmentListItemTitle {
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.large {
  height: 4rem;
}
.small {
  height: 2.5rem;
}
.equipmentListItemContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.094);
}
.equipmentListItemMetadata {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.equipmentListClock {
  margin-left: auto;
}
.equipmentListItemIdentifier {
  font-size: 11px;
  color: var(--metadata-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
