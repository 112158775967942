.formFields{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

#loginForm{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}
.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.buttonContainer button{
    min-width: 35%;
}
.header {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
  .logo {
    height: 2rem;
  }
  
  .welcomeText {
    font-size: 1.5rem;
    font-weight: 500;
  }