.tooltip {
  position: absolute;
  transform: translate(-50%, -100%);
  z-index: 10;
  background-color: #fff;
  border: 2px solid var(--metadata-color);
  border-radius: 5px;
  padding: var(--spacing-xs) var(--spacing-sm);
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
}
.tooltip:before {
  display: block;
  position: absolute;
  content: "";
  top: calc(100% + 1px);
  transform: translate(50%, -50%) rotate(-315deg) translateZ(0);
  right: 50%;
  height: 10px;
  width: 10px;
  background: linear-gradient(-45deg, #fff 52%, transparent 0);
  border: inherit;
  border-width: 0 2px 2px 0;
  border-radius: 0 0 2px 0;
}
.metadata {
  color: var(--metadata-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 12rem;
  font-size: 14px;
}
.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 12rem;
}
