.calendarContainer {
  padding-block: 10px;
  display: flex;
  overflow: auto;
  padding-inline: var(--spacing-sm);
}
.calendarHourText {
  color: var(--metadata-color);
  font-size: x-small;
  user-select: none;
  transform: translateY(-6px);
}
.calendarHourContainer {
  display: flex;
  height: 60px;
}
.calendarHourSlotColumn {
  flex-direction: column;
  flex: 1;
  position: relative;
}
.calendarHourSlot {
  height: 60px;
  border-top: 1px solid var(--metadata-color);
  user-select: none;
  box-sizing: border-box
}
.calendarHourColumn {
  width: 50px;
}
.eventBoxContent {
  text-align: center;
  letter-spacing: 0.1px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  height: 100%;
  width: 90%;
  justify-content: center;
}
.eventBox {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  container-type: size;
  color: var(--primary-color);
  cursor: pointer;
  box-sizing: border-box
}
.eventBoxTitle {
  font-weight: 600;
  font-size: 16px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 100%;
  overflow: hidden;
}
.eventBox:hover {
  box-shadow: 0 0 0 1px var(--primary-color);
}
.eventBoxActive {
  background-color: var(--primary-color-light);
  color: var(--primary-text-color);
  cursor:default;
  border-width: 1px;
}
.eventBoxTime {
  font-size: 12px;
}
.eventBoxDragElement.top{
  transform: translateY(-50%);
  top: 0;
}
.eventBoxDragElement.bottom{
  transform: translateY(50%);
  bottom: 0;
}
.eventBoxDragElement{
  position: absolute;
  height: 10px;
  width: 100%;
  cursor: ns-resize;
}

@container (height < 31px) {
  .eventBoxTime {
    font-size: x-small;
    flex: 1;
  }
  .eventBoxContent {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .eventBoxTitle {
    padding-inline: 20px;
    font-size: x-small;
    flex: 1;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
