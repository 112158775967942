.modal {
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  padding: 0;
  background-color: white;
  overflow: hidden;
  min-width: 30vw;
}
.header {
  background-color: var(--primary-color);
  padding: 1rem;
  display: flex;
  justify-content: center;
  position: relative;
}
.title {
  color: white;
  font-weight: 500;
  font-size: 18px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.body {
  padding: 1rem;
  flex: 1;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000005f;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container > * {
  padding: 1rem;
}
.modal::backdrop {
  z-index: 10;
}
.closeButton {
  position: absolute;
  right: 1.5rem;
  color: white;
  cursor: pointer;
}
.closeButton:active {
  color: var(--supplementary-color);
}
.closeButton:hover {
  color: var(--supplementary-color);
}

@media only screen and (max-width: 1024px) {
  .modal{
    min-width: 40vw;
  }
}


@media only screen and (max-width: 768px) {
  .modal {
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    margin-bottom: 0;
    animation: popup 0.4s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .backdrop {
    justify-content: flex-end;
  }
  .modal .ant-select-dropdown {
    background-color: red;
  }
  .footer {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 2rem;
  }
  .body{
    max-height: 80dvh;
    overflow: auto;
  }
}

@keyframes popup {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
