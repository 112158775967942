.bookingIconContainer {
    background-color: var(--tertiary-color);
    height: 75%;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    justify-content: center;
    margin-right: 10px;
    border-radius: 5px;
  }